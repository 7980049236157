import { Link } from "react-router-dom";
import { Category } from "../../models/category"

interface CategoryProps {
    category: Category,
    index: number,
}

export const CategoryItem: React.FC<CategoryProps> = ({ category, index }) => {
    const left = index % 2 === 0;

    return (
        <div className="flex flex-col lg:flex-row w-full lg:space-x-20">
            <CategoryText name={category.name} left={left} categoryId={category.id} classNameSupplement="block lg:hidden" />
            {index % 2 === 0 && <CategoryImg image={category.images[0]} left={left} />}
            <CategoryText name={category.name} left={left} categoryId={category.id} classNameSupplement="hidden lg:flex" />
            {index % 2 === 1 && <CategoryImg image={category.images[0]} left={left} />}
            <div className={`flex lg:hidden mx-auto mt-5`}>
                <Link to={"/categories/" + category.id}>
                    <button className="bg-[#3719EB] text-white rounded px-5 py-2 text-xl">Zobrazit</button>
                </Link>
            </div>
        </div>
    )
}

interface CategoryImgProps {
    image: string,
    left: boolean,
}

export const CategoryImg: React.FC<CategoryImgProps> = ({ image, left }) => {
    return (
        <div className={`flex lg:w-1/2 justify-center ${!left ? 'lg:justify-start' : 'lg:justify-end'}`}>
            <img src={process.env.PUBLIC_URL + image} alt="" className="mt-5 lg:mt-0 w-2/3 aspect-video object-cover border border-black border-2 shadow-xl rounded" />
        </div>
    )
}

interface CategoryTextProps {
    name: string,
    left: boolean,
    categoryId: number,
    classNameSupplement?: string,
}

export const CategoryText: React.FC<CategoryTextProps> = ({ name, left, categoryId, classNameSupplement }) => {
    return (
        <div className={`${classNameSupplement} flex w-full lg:w-1/2 justify-center ${left ? 'lg:justify-start' : 'lg:justify-end'}`}>
            <div className="flex flex-col justify-between">
                <div className={`border-[#3719EB] border-b-[10px] lg:border-b-[20px] ${left ? 'lg:border-r-[20px]' : 'lg:border-l-[20px]'} p-2 rounded-full`}>
                    <div className="text-2xl lg:text-4xl">{name}</div>
                </div>

                <div className={`hidden lg:flex ${!left ? 'self-end' : ''}`}>
                    <Link to={"/categories/" + categoryId}>
                        <button className="bg-[#3719EB] text-white rounded px-5 py-2 text-xl">Zobrazit</button>
                    </Link>
                </div>
            </div>
        </div>
    )
}