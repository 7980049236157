import '../../css/background.css'

export const AboutUs = () => {
    return (
        <div className='flex flex-col about_us_background p-10 pb-20'>
            <div className='text-white mx-auto text-6xl'>
                Kdo jsme?
            </div>
            <div className="flex flex-col lg:flex-row items-center mt-10">
                <div className="w-full lg:w-1/2 px-2 md:px-10 text-white text-center text-md lg:text-xl">
                    Naše společnost byla založena v roce 2014 jako rodinná firma s cílem přinášet kvalitní cukrovinky na český trh. Specializujeme se na dovoz a distribuci sladkostí s výhradním zastoupením renomované turecké společnosti Tayaş pro Českou republiku a ovocných tyčinek od ukrajinského výrobce Saleks.
                    Naším principem je efektivní distribuce zboží do velkoskladů po celé republice, přičemž se zaměřujeme na paletové objednávky, které zajišťují rychlé a spolehlivé dodání našim obchodním partnerům. Díky tomu garantujeme kvalitní produkty za výhodných podmínek.
                </div>
                <div className="w-full lg:w-1/2 mt-10 lg:mt-0 lg:px-20">
                    <img src={process.env.PUBLIC_URL + "/apple.jpg"} alt="apple" className='mx-auto object-cover border border-white border-4 w-3/4' />
                </div>
            </div>
        </div>
    )
}